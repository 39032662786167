import * as React from 'react';
import { ImportTypeEnum } from '@app/common';
import { Marquee, MarqueeGroup, Wrapper } from './partners.styled';
import { Platform } from '../../app/platform/platform';

export const Partners: React.FC = () => {
    const platforms = [
        ImportTypeEnum.Degiro,
        ImportTypeEnum.Etoro,
        ImportTypeEnum.Xtb,
        ImportTypeEnum.Trading212,
        ImportTypeEnum.InteractiveBrokers,
        ImportTypeEnum.Coinbase,
        ImportTypeEnum.Anycoin,
        ImportTypeEnum.Portu,
        ImportTypeEnum.Binance,
        ImportTypeEnum.Fio,
        ImportTypeEnum.Snowball,
        ImportTypeEnum.YahooFinance,
        ImportTypeEnum.Coinmate,
        ImportTypeEnum.Patria,
        ImportTypeEnum.Lynx,
        ImportTypeEnum.CoinbasePro,
        ImportTypeEnum.Revolut,
    ];

    return (
        <Wrapper>
            <Marquee>
                <MarqueeGroup>
                    {platforms.map((platform) => (
                        <Platform key={platform} type={platform} />
                    ))}
                </MarqueeGroup>
                <MarqueeGroup>
                    {platforms.map((platform) => (
                        <Platform key={platform} type={platform} />
                    ))}
                </MarqueeGroup>
            </Marquee>
        </Wrapper>
    );
};
