import { NextRouter, useRouter } from 'next/router';
import React from 'react';
import { UserRoleEnum } from '@app/common';
import { observer } from 'mobx-react-lite';
import { routes } from '../../config/routes';
import { useRootStore } from '../../providers/root-store-provider';
import { RootStore } from '../stores/root-store';
import { UserProfileMobxDto } from '../mobx/dtos/user/user-profile-mobx-dto';
import { LookupKeyEnum } from '../../defs/api';
import { CookieService } from '../services/cookie-service';

export const hasAdminRole = (userProfile: UserProfileMobxDto | null) => userProfile?.role === UserRoleEnum.Admin;

/**
 * Premium uživatel by měl být z ceníku přesměrován do aplikace
 */
export const restrictPremiumUserAccessPricingPage = (router: NextRouter, rootStore: RootStore): boolean => {
    const isOnPricingPage = router.asPath === routes.app.pricing;

    if (rootStore.authStore.userHasPremiumTariff && isOnPricingPage) {
        router.push(routes.app.index);
        return true;
    }

    return false;
};

/**
 * Nepřihlášený uživatel se nesmí dostat na protectedPage
 */
export const restrictProtectedPage = (router: NextRouter, rootStore: RootStore): boolean => {
    if (!rootStore.authStore.userProfile && !rootStore.cookieService.hasJwtToken()) {
        rootStore.cookieService.setRedirectUrl(router.asPath);
        router.push(`${routes.web.index}?login=true`);
        return true;
    }
    return false;
};

/**
 * Uživatel, který je na wainting listu se nesmí dostat do aplikace
 */
export const restrictProtectedPageForUserOnWaitingList = (router: NextRouter, rootStore: RootStore) => {
    const isOnWaitingList = rootStore.authStore.userProfile?.isOnWaitingList || false;
    if (isOnWaitingList) {
        router.push(routes.web.waitingList);
        return true;
    }

    return false;
};

/**
 * Free uživatel se nemůže dostat do aplikace.
 */
export const restrictFreeUserAccessProtectedPage = (
    router: NextRouter,
    rootStore: RootStore,
    cookieService: CookieService,
): boolean => {
    const isOnPricingPage = router.asPath === routes.app.pricing;
    const isOnAffiliatePage = router.asPath === routes.app.affiliate;

    if (
        !rootStore.authStore.userHasPremiumTariff &&
        !isOnPricingPage &&
        cookieService.getRedirectUrl() !== routes.app.affiliate &&
        !isOnAffiliatePage
    ) {
        router.push(routes.app.pricing);
        return true;
    }
    return false;
};

/**
 * Pouze administrátor může přistupovat na adminPage
 */
export const restrictAccessAdminPage = (router: NextRouter, rootStore: RootStore) => {
    if (!hasAdminRole(rootStore.authStore.userProfile)) {
        router.push(routes.app.index);
        return true;
    }
    return false;
};

/**
 * Pokud je uživatel na pricing page a má zájem o předplatné, tak se přesměruje na checkout
 */
export const restrictAccessToPricingPageByInterestedInSubscription = (rootStore: RootStore) => {
    if (!rootStore.authStore.userHasPremiumTariff) {
        if (process.env.NEXT_PUBLIC_IS_WAITING_LIST === 'true') {
            const interestedInSubscription = rootStore.authStore.userProfile?.interestedInSubscription;
            if (interestedInSubscription) {
                if (interestedInSubscription) {
                    rootStore.userStore.updateInterestedInSubscription(null);
                    rootStore.stripeStore.createCheckoutSession(interestedInSubscription as string as LookupKeyEnum);
                }
                return true;
            }
        } else {
            const interestedInSubscription = rootStore.cookieService.getInterestedInSubscription();
            if (interestedInSubscription) {
                if (interestedInSubscription) {
                    rootStore.cookieService.setInterestedInSubscription(null);
                    rootStore.stripeStore.createCheckoutSession(interestedInSubscription);
                }
                return true;
            }
        }
    }

    return false;
};

export function withAuth<T extends object>(component: React.FC<T>, protectedPage = true, adminPage = false) {
    return observer((props: T) => {
        const router = useRouter();
        const pricingPage = router.asPath === routes.app.pricing;

        const rootStore = useRootStore();

        if (protectedPage) {
            if (restrictProtectedPage(router, rootStore)) {
                return null;
            }

            if (restrictProtectedPageForUserOnWaitingList(router, rootStore)) {
                return null;
            }

            if (restrictPremiumUserAccessPricingPage(router, rootStore)) {
                return null;
            }

            if (restrictFreeUserAccessProtectedPage(router, rootStore, rootStore.cookieService)) {
                return null;
            }

            if (adminPage) {
                if (restrictAccessAdminPage(router, rootStore)) {
                    return null;
                }
            }

            if (pricingPage) {
                if (restrictAccessToPricingPageByInterestedInSubscription(rootStore)) {
                    return null;
                }
            }

            // pokud mám přístup na protectedPage a mám redirectCookie, tak přesměruji na danou stránku
            rootStore.cookieService.loadRedirectUrl();
        }

        if (!rootStore.authStore.userProfile?.agreedTerms) {
            rootStore.termsAgreementDialogStore.openDialog();
        }

        return component(props);
    });
}
