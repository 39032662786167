import styled, { css } from 'styled-components';
import { HeadlineSizeType } from '../../../theme/type/headline-type';
import { GapSizeType } from '../../../theme/type/gap-type';
import { PaletteColorType } from '../../../theme/type/palette-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !['level', 'inverse', 'marginTop', 'gap', 'align', 'variant', 'hasAction'].includes(prop),
})<{
    level: HeadlineSizeType;
    inverse?: boolean;
    marginTop: GapSizeType;
    gap: GapSizeType;
    align?: 'left' | 'center' | 'right';
    variant: PaletteColorType;
    hasAction: boolean;
}>`
    ${({ theme, inverse, align, gap, level, marginTop, variant, hasAction }) => css`
        color: ${variant ? theme.palette.color[variant].main : theme.headline.base.color};
        font-size: ${theme.headline.size[level].mobile.fontSize};
        font-weight: 700;
        line-height: ${theme.headline.size[level].mobile.lineHeight};
        margin: 0 0 ${theme.gap[gap]};
        margin-top: ${theme.gap[marginTop]};

        @media ${theme.breakpoints.md.min} {
            font-size: ${theme.headline.size[level].fontSize};
            line-height: ${theme.headline.size[level].lineHeight};
        }

        ${hasAction &&
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `}

        ${inverse &&
        css`
            color: ${theme.palette.color.white.main};
        `}

        ${align &&
        css`
            text-align: ${align};
        `}

        a {
            color: ${theme.palette.common.link.main};
            text-decoration: underline;

            &:hover {
                color: ${theme.palette.common.link.hover};
            }
        }

        > span {
            color: ${theme.palette.color.primary.main};
        }
    `}
`;

export const Action = styled.div``;
